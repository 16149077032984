<template>
  <input :type="type" v-model="model" />
</template>
<script>

export default {
  props: {
    modelValue: {},
    type: {default: 'text'},
    delimiters: {default: () => {return /,| /}},
  },
  data () {
    return {
      model: this.modelValue || [],
    }
  },
  methods: {
    cleanValue (v) {
      return v.split(this.delimiters).map((r) => {
        return r.trim()
      }).filter((r) => {
        return !!r
      })
    }
  },
  watch: {
    model (v) {
      this.$emit('update:modelValue', this.cleanValue(v))
    }
  }
}
</script>