export function stringToArray(str, trim = true, separators = [" ", ",", ";", "\n"]) {
  let noop = "_____NOOP_____"
  let final = str
  separators.forEach(s => {
    // cf https://stackoverflow.com/a/36976348/2844093
    final = final.split(s).join(noop)
  })
  final = final.split(noop)
  return [...new Set(final)].map(s => {
    if (trim) {
      return s.trim()
    }
    return s
  }).filter(s => {
    return !!s
  })
}
