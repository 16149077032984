<template>
  <table class="no-headers">
    <tbody>
      <tr v-if="!hidden['name']">
        <td><translate translate-context="*/*/*">ID</translate></td>
        <td>
          <router-link :to="{name: 'dashboard.machines.detail', params: {id: machine.name, group: $store.state.selectedGroupId}}">
            {{ machine.name.slice(0, 8) }}
          </router-link>
        </td>
      </tr>
      <tr v-if="!hidden['session_locked']">
        <td><translate translate-context="*/*/*">Session status</translate></td>
        <td v-if="machine.session_locked">
          <translate translate-context="*/*/*">Locked</translate>
        </td>
        <td v-else-if="machine.session_locked === false">
          <translate translate-context="*/*/*">Unlocked</translate>
        </td>
        <td v-else>
          <translate translate-context="*/*/*">Unknown</translate>
        </td>
      </tr>
      <tr v-if="machine.hostname">
        <td><translate translate-context="*/*/*">Hostname</translate></td>
        <td>{{ machine.hostname }}</td>
      </tr>
      <tr v-if="!hidden['ip.local'] && machine.ip.local">
        <td><translate translate-context="*/*/*">Local IP</translate></td>
        <td>{{ machine.ip.local }}</td>
      </tr>
      <tr>
        <td><translate translate-context="*/*/*">Public IP</translate></td>
        <td>{{ machine.ip.public }}</td>
      </tr>
      <tr>
        <td><translate translate-context="*/*/*">Location</translate></td>
        <td>{{ machine.ip.country_name }}</td>
      </tr>
      <tr v-if="!hidden['version']">
        <td><translate translate-context="*/*/*">Version</translate></td>
        <td>{{ machine.version }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: ["machine", 'hide'],
  computed: {
    hidden () {
      let h = {}
      if (this.hide) {
        this.hide.forEach((f) => {
          h[f] = true
        })
      }
      return h
    }
  }
}
</script>