<template>
  <div class="warning message">
    <table class="no-headers">
      <tbody>
        <tr>
          <td><translate translate-context="*/*/*">Type</translate></td>
          <td><strong>{{ issueHelp.label }}</strong></td>
        </tr>
        <tr>
          <td><translate translate-context="*/*/*">Description</translate></td>
          <td>
            {{ issueHelp.description }}
          </td>
        </tr>
        <tr>
          <td><translate translate-context="*/*/*">Code</translate></td>
          <td>{{ issue.code }}</td>
        </tr>
        <tr>
          <td><translate translate-context="*/*/*">First seen</translate></td>
          <td v-if="issue.first_update">
            <time class="discrete" :datetime="issue.first_update" :title="issue.first_update">
              {{ ago(issue.first_update) }}
            </time>
          </td>
          <td v-else>N/A</td>
        </tr>
        <tr>
          <td><translate translate-context="*/*/*">Last seen</translate></td>
          <td v-if="issue.last_update">
            <time class="discrete" :datetime="issue.last_update" :title="issue.last_update">
              {{ ago(issue.last_update) }}
            </time>
          </td>
          <td v-else>N/A</td>
        </tr>
      </tbody>
    </table>
    <template v-if="issueHelp.solution.length > 0">
      <h5>
        <strong v-translate translate-context="*/*/*">Solution</strong>
      </h5>
      <ul>
        <li v-for="s in issueHelp.solution" :key="s">
          {{ s }}
        </li>
      </ul>
    </template>
    <template v-if="issueHelp.hints.length > 0">
      <h5>
        <strong v-translate translate-context="*/*/*">Hints</strong>
      </h5>
      <ul>
        <li v-for="h in issueHelp.hints" :key="h">
          {{ h }}
        </li>
      </ul>
    </template>
  </div>
</template>
<script>
import { ago } from '@/time'

import {getIssueHelp, getIssues} from '@/copy'

export default {
  props: ["issue"],
  data () {
    return {
      ago
    }
  },
  computed: {
    issues () {
      return getIssues(this)
    },
    issueHelp () {
      return getIssueHelp(this, this.issue)
    }
  }
}
</script>
