<template>
  <table class="no-headers" v-if="stream">
    <tbody>
      <tr>
        <td><translate translate-context="*/*/*">Latency</translate></td>
        <td>{{ stream.latency }}ms</td>
      </tr>
      <tr>
        <td><translate translate-context="*/*/*">Voice level</translate></td>
        <td>{{ stream.voice_level }}dB</td>
      </tr>
      <tr>
        <td><translate translate-context="*/*/*">Noise level</translate></td>
        <td>{{ stream.noise_level }}dB</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: ["stream"]
}
</script>